.countdown-timer-wrapper {
  width: inherit;
  flex: 1;
}

.countdown-timer-container {
  display: flex;
  align-items: center;
  width: auto;
}

.countdown-timer-time-container {
  display: flex;
  align-items: center;
  margin-left: 56px;
}

.countdown-timer-time-value-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  width: 64px;
  min-width: 64px;
}

.countdown-timer-time {
  background: #e9f0ff;
  border-radius: 8px;
  padding: 4px;
  margin-right: 4px;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: #4d6cd9;
  display: inline;
}

.countdown-timer-label {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  margin-top: 8px;
  color: #30374c;
}

@media only screen and (max-width: 780px) {
  .countdown-timer-time-container {
    margin-left: 15px;
    width: auto;
  }
  .countdown-timer-dots {
    width: 0;
  }

  .countdown-timer-time {
    font-size: 20px;
    line-height: 20px;
  }
  .countdown-timer-time-value-container {
    width: auto;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .countdown-timer-time-container {
    margin-left: 5px;
    width: auto;
  }
  .countdown-timer-time-value-container {
    width: auto;
    margin-left: 0px;
  }
}

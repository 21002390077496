@import "../../scssPartials/sharedStyles";
// @import "../../assets/home-hero-image.svg";

.home-wrapper {
  padding-bottom: 8rem;
}

.home-container {
  background: #ffffff;
}

.home-banner {
  background-image: url("../../assets/home-hero-image.svg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 56.25rem;
}

.home-harness-text {
  @include font(2.25rem, bold, #ffffff, 3.0625rem);
  position: relative;
  top: 18.1875rem;
  width: 36.5625rem;
  left: 12.75rem;
}

.home-buttons {
  position: relative;
  display: flex;
  top: 28.3125rem;
  left: 12.75rem;
}

.home-discover-more {
  margin-left: 2.5rem;
}

.home-our-clients-container {
  margin: 4.43625rem 10rem 0 10rem;
}

.home-site-metrics {
  @include display-align(column, space-evenly, center);
  background: #f1f7ff;
  padding: 3.75rem;
  height: 28.125rem;
}

.home-hiringhood-vs-others {
  @include display-align(column, space-between, center);
  background: #ffffff;
}

.home-driving-impact {
  @include display-align(column, space-between, center);
  background: #ffffff;
  height: 35.0625rem;
  padding: 4.43rem 0;
  margin: 0 6.25rem 0 6.25rem;
}

.home-why-hiringhood {
  @include display-align(column, space-between, center);
  background: #ffffff;
  padding: 3.75rem;
  margin: 0 6.25rem 0 6.25rem;
}

.home-faq-text {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
  text-align: center;
  margin-top: 8.75rem;
}

.home-faq-image {
  display: block;
  margin: 2.5rem auto 0 auto;
}

.home-faq-container {
  margin-top: 3rem;
}

.home-blogs-container {
  margin: 8.75rem 3rem 0 3rem;
}

.home-meet-the-founder-container {
  margin: 4.43rem 3rem 0 3rem;
}

.home-whats-in-it {
  background: #4d6cd9;
  height: 58.75rem;
  @include display-align(column, space-evenly, center);
}

.home-what-customers-are-saying {
  height: 36.875rem;
  margin-top: 4.43rem;
  @include display-align(column, space-between, center);
}

.home-lets-chat {
  margin: 4.43rem 3rem 7.125rem 3rem;
}

.home-partners-container {
  margin: 4.43rem 10rem 0 10rem;
}

.home-page-faq {
  margin: 4.43rem 4.5rem 0 4.5rem;
}

.home-our-how-hiringhood-works-container {
  margin: 4.43625rem 10rem 0.68rem 10rem;
  text-align: center;
}

.home-our-how-hiringhood-works-container video {
  margin-top: 1.5rem;
  border: 0.0625rem solid #d6d6d6;
  width: -webkit-fill-available;
  width: -moz-available;
}

$small: 300px;
$medium: 900px;
// managing the css between the small and medium screens
@media only screen and (min-width: $small) and (max-width: $medium) {
  .home-banner {
    height: auto;
    width: auto;
  }

  .home-our-clients-container {
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 300px) {
  .home-harness-text {
    font-size: 1rem;
    line-height: 1.5rem;
    position: relative;
    top: 6.5rem;
    left: 2rem;
    width: 15rem;
    margin-bottom: 1rem;
  }

  .home-banner {
    height: auto;
    width: auto;
  }

  .home-our-clients-container {
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .home-buttons {
    top: 7rem;
    left: 1.75rem;
  }

  .home-our-how-hiringhood-works-container {
    margin: 4rem 0 0 0;
    text-align: center;
  }
  .site-metrics-div {
    display: flex;
    flex-direction: column;
  }
  .home-site-metrics {
    height: 100%;
  }

  .home-driving-impact {
    height: 100%;
  }
  .home-hiringhood-vs-others {
    display: none;
  }
  .home-why-hiringhood {
    display: none;
    margin: 0;
    padding: 0;
  }

  .home-meet-the-founder-container {
    display: block;
    margin: 1rem;
    justify-content: center;
    font-size: 1.5rem;
  }

  .home-whats-in-it {
    display: none;
  }

  .home-what-customers-are-saying {
    display: none;
  }

  .home-partners-container {
    display: none;
  }

  .home-page-faq {
    margin: 0;
  }
}

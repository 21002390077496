.contest-detail-card-container {
  position: relative;
  display: flex;
  width: 58.875rem;
  min-height: 28.0625rem;
}

.contest-detail-details-container {
  display: flex;
  flex-direction: column;
  padding: 4.875rem 0 0 0;
  width: 19.75rem;
}

.contest-detail-badge {
  position: absolute;
  top: 3.875rem;
  margin-left: 1.1875rem;
  margin-right: 0.78rem;
}

.contest-detail-cash-rewards-container {
  margin-top: 2rem;
  align-self: center;
}

.contest-details-section1 {
  margin: 3rem 0 2rem 0;
  padding-left: 1.96875rem;
  width: 15.9375rem;
  max-width: 15.9375rem;
  border-left: 0.0625rem solid #aab0cb;
}

.contest-details-section2 {
  margin: 3rem 0 0 4.8125rem;
  width: 14.5625rem;
}

.contest-details-detail-container {
  display: flex;
  align-items: center;
  min-height: 2.75rem;
  margin-top: 1rem;
}

.contest-details-detail-text {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-left: 1rem;
  word-break: break-word;
  /**/
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%;
}

.contest-detail-icons-wrapper {
  position: relative;
}

.contest-detail-icons-container {
  position: absolute;
  top: 1.625rem;
  right: 1.625rem;
}

.contest-details-card-button {
  margin-top: 1.875rem;
  margin-bottom: 1.5rem;
  margin-left: 3.75rem;
  width: 12.6875rem;
}

.contest-detail-icons {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

@media only screen and (max-width: 780px) {
  .contest-detail-card-container {
    display: block;
    width: 100%;
  }
  .contest-detail-details-container {
    width: 100%;
  }
  .contest-details-card-button {
    align-self: center;
    margin-left: 0px;
    margin-bottom: 0px;
  }
  .contest-details-section1 {
    margin: 2.125rem 0 0 0;
    padding-left: 0.96875rem;
    width: 100%;
    max-width: 100%;
  }
  .contest-details-section2 {
    margin: 2.125rem 0 0 0;
    padding-left: 0.96875rem;
    padding-bottom: 1rem;
    width: 100%;
  }
}

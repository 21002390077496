.contest-card-wrapper {
  height: 20.6875rem;
  overflow: hidden;
  transition: 3s;
  box-shadow: 0rem 0rem 5rem rgba(206, 212, 240, 0.8);
  border-radius: 1rem;
}

.contest-card-wrapper-hovered {
  z-index: 2;
  overflow: visible;
  width: 21.875rem;
}

.contest-card-container {
  position: relative;
  padding: 5rem 0 1.6875rem 0;
  display: flex;
  flex-direction: column;
}

.contest-card-job-posted-duration {
  position: absolute;
  top: 1.6875rem;
  left: 1rem;
  display: flex;
  align-items: center;
}

.contest-card-job-posted-duration p {
  font-weight: bold;
  color: #aab0cb;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-left: 0.5rem;
}

.contest-card-badge {
  position: absolute;
  top: 4.61875rem;
  left: 0.5rem;
}

.contest-card-top-icons-container {
  position: absolute;
  right: 1.5rem;
  top: 1.71875rem;
}

.contest-card-menu {
  margin-left: 1rem;
}

.contest-card-top-icon {
  cursor: pointer;
}

.contest-card-company-container {
  display: flex;
  align-items: center;
  align-self: center;
  margin-top: 1.4375rem;
}

.contest-card-company-container p {
  margin-left: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.contest-card-cash-reward-container {
  margin-top: 1.5rem;
  align-self: center;
}

.contest-card-join-hunt {
  margin: 2.1875rem 2.285625rem 1.5rem 2.285625rem;
}

.contest-card-join-hunt-btn {
  width: 100%;
}

.contest-card-join-hunt a {
  text-decoration: none;
}

.contest-card-company-experience-container {
  display: flex;
  justify-content: space-between;
  height: 2.4375rem;
  max-height: 2.4375rem;
  align-self: center;
  margin-top: 1.5625rem;
}

.contest-card-company-experience-container p {
  margin-left: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.contest-card-company-container-hovered {
  display: flex;
  align-items: center;
  align-self: center;
}

.contest-card-experience-container {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}

.contest-card-icons-container {
  display: flex;
  justify-content: space-around;
  margin: 0 2.285625rem;
}

.contest-card-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contest-card-icon-text-container {
  display: flex;
  align-items: center;
}

.contest-card-icon-text {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  text-align: center;
  color: #626880;
  margin-left: 0.375rem;
}

.contest-card-icons-container-text {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  align-items: center;
  color: #626880;
  margin-top: 0.25rem;
}

.contest-card-icon-conatiner {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.contest-card-icons-text {
  font-size: 1rem;
  font-weight: bold;
  color: #626880;
  line-height: 1.125rem;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 780px) {
  .contest-card-wrapper {
    height: auto;
    width: -webkit-fill-available;
    width: -moz-available;
  }
  .contest-card-company-container {
    display: none;
  }
}

.contest-wrapper {
  margin: 0.5rem 3rem;
}

.contest-container {
  display: flex;
  width: --webkit-fill-available;
  margin-bottom: 1.5rem;
}

.contest-details {
  flex: 1;
}

.contest-timeline-container {
  margin-left: 1.5rem;
}

.contest-timer-quota-container {
  display: flex;

  margin-top: 1.375rem;
}

.contest-timer-wrapper {
  flex: 0.5;
}

.contest-quota-wrapper {
  margin-left: 1.5rem;
  flex: 0.5;
}

@media only screen and (max-width: 780px) {
  .contest-wrapper {
    margin: 0 1.1875rem;
  }
  .contest-timer-quota-container {
    display: block;
  }
  .contest-container {
    display: block;
  }
  .contest-quota-wrapper {
    margin-left: 0px;
    width: 100%;
    margin-bottom: 1rem;
  }
  .contest-timeline-container {
    margin-left: 0px;
  }
}

.contest-list-card-container {
  position: relative;
  display: flex;
}

.contest-list-container {
  padding: 1.3125rem 0 0 0;
}

.contest-list-card-job-post-duration {
  display: flex;
  font-weight: bold;
  font-size: 0.8125rem;
  line-height: 1.125rem;
  color: #aab0cb;
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
}

.contest-list-card-job-post-duration p {
  align-self: center;
  margin-left: 1.3125rem;
}

.contest-list-card-details-container {
  position: relative;
  padding-bottom: 3rem;
  padding-right: 1rem;
  width: -webkit-fill-available;
  width: -moz-available;
}

.contest-list-card-top-icons-container {
  position: absolute;
  right: 1.5rem;
  top: 1.71875rem;
}

.contest-list-card-menu {
  margin-left: 2.1875rem;
}

.contest-list-card-top-icon {
  cursor: pointer;
}

.contest-list-details-container {
  border-right: 0.0625rem solid #dfe5ff;
  margin: 6.21875rem 0 1.875rem 0;
  padding: 0 0 0 4rem;
}

.contest-list-badge {
  position: absolute;
  top: 5.71875rem;
  padding-left: 1.1875rem;
}

.contest-list-card-icons-container {
  padding: 0 0.5rem 1.25rem 1.7725rem;
  border-bottom: 0.0625rem solid #dfe5ff;
  margin: 5.75rem 0 0 0;
  display: flex;
}

.contest-list-card-icon-stats-container {
  display: flex;
  align-self: flex-end;
  margin-left: 1.5rem;
}

.contest-list-card-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 3.4375rem;
}

.contest-list-card-icon-text-container {
  display: flex;
  align-items: center;
}

.contest-list-card-icon-text {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  text-align: center;
  color: #626880;
  margin-left: 0.375rem;
}

.contest-list-card-icons-container-text {
  font-size: 0.8125rem;
  line-height: 1.125rem;
  align-items: center;
  color: #626880;
  margin-top: 0.25rem;
}

.contest-list-section-container {
  display: flex;
  margin: 0 0 1.5625rem 0;
  padding: 0 0.5rem 0 1.7725rem;
  justify-content: space-between;
}

.contest-list-section {
  min-width: 10rem;
}

.contest-lists-detail-container {
  display: flex;
  align-items: center;
  min-height: 2.75rem;
  margin-top: 1rem;
  overflow-wrap: break-word;
}

.contest-list-card-button {
  align-self: center;
  margin-left: 1.5rem;
}

.contest-lists-detail-text {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-left: 1rem;
}

.contest-list-icons-wrapper {
  position: relative;
}

.contest-list-icons-container {
  position: absolute;
  top: 1.625rem;
  right: 1.625rem;
}

.contest-list-card-join-hunt {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.contest-list-card-join-hunt-button {
  width: 11.75rem;
  align-self: flex-end;
}

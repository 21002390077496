.contest-tabs-container {
  display: flex;
  justify-content: space-between;
  background: #4d6cd9;
  border-radius: 1rem 1rem 0 0;
  width: auto;
}

.contest-tabs-tab {
  margin: 0.5rem;
}

.contest-tabs-active-tab {
  background: #f1f7ff;
  border-radius: 12px;
}

.contest-tabs-text {
  font-weight: bold;
  font-size: 1.0625rem;
  line-height: 1.25rem;
  text-align: center;
  color: #ffffff;
  padding: 0.625rem 1.361875rem;
  cursor: pointer;
}

.contest-tabs-active-text {
  font-size: 1rem;
  line-height: 1.375rem;
  color: #4d6cd9;
  cursor: default;
}

.contest-tabs-terms-container {
  margin: 2rem 2rem 0 2rem;
  padding-bottom: 1.875rem;
}

.contest-tabs-faq-container {
  margin: 1.75rem 2rem 0 2rem;
  padding-bottom: 2.5rem;
}

.contest-tabs-overview-container {
  margin: 2rem 2rem 0 2rem;
  padding-bottom: 3.5rem;
}

.contest-tabs-job-description-container {
  margin: 1.5rem 2rem 0 2rem;
  padding-bottom: 1.75rem;
}

.contest-tabs-partners-container {
  margin: 0 1.66rem 0 1.665rem;
}

.contest-tabs-stats-container {
  margin-top: 1.5rem;
}

@media only screen and (max-width: 780px) {
  .contest-tabs-container {
    overflow-x: auto;
    padding-right: 0.5rem;
  }

  .contest-tabs-text {
    display: flex;
    justify-content: space-between;
  }
  .contest-tabs-img {
    padding-top: none !important;
  }
}
